html {
  font-family: "Noto Sans Display", sans-serif;
  scroll-padding-top: 18rem;
  font-size: 62.5%;
}

body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr:not([size]) {
  height: 0;
}

body > iframe {
  display: none !important;
}

strong {
  font-weight: bold;
}

body {
  background: #030d3e;
}

/* -------------------------------------------------------------------------- */
/*                                SLICK SLIDER                                */
/* -------------------------------------------------------------------------- */

.slick-dots li button::before {
  font-size: 1.2rem;
}

.slick-slide > div {
  margin: 0 1rem;
}

.slick-dots li.slick-active button::before {
  opacity: 0.75;
  color: rgba(196, 196, 196, 0.9);
}

.slick-dots li button::before {
  color: rgba(196, 196, 196, 0.9);
}

/* -------------------------------------------------------------------------- */
/*                                GLOBAL INPUT                                */
/* -------------------------------------------------------------------------- */

.ant-input,
.ant-input-affix-wrapper {
  border-radius: 0.8rem !important;
}

.ant-btn.ant-btn-primary.ant-btn-block {
  border-radius: 0.8rem !important;
}

.ant-form-item-explain,
.ant-form-item-extra {
  padding-top: 0.4rem;
}

.ant-notification-notice-close .anticon.anticon-close > svg {
  height: 1.4rem !important;
  width: 1.4rem !important;
  color: #494949 !important;
}

.ant-select-dropdown {
  background-color: #0b4f92 !important;
  border-radius: 0.8rem;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #0b4f92;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(20, 146, 230, 0.5);
}

.ant-notification-notice {
  background: #1f2336;
  color: #fff;
  border-radius: 0.8rem;
}

.ant-notification-notice-message {
  color: #fff;
}

.ant-notification-notice-close .anticon.anticon-close > svg {
  color: #fff;
}

@media only screen and (max-width: 80em) and (min-width: 56.3125em) {
  .slick-dots {
    bottom: -8rem !important;
  }
}

/* -------------------------------------------------------------------------- */
/*                                 INFO MODAL                                 */
/* -------------------------------------------------------------------------- */
.ant-modal-content {
  background-color: #1f2336 !important;
  border: 1px solid #4e5266;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px !important;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
  border: 1px solid #4e5266 !important;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
}

.ant-modal-confirm-body-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-modal-confirm-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0 !important;
}

.ant-modal-confirm-body > .anticon {
  margin-right: 0 !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  color: #fff !important;
  font-size: 1.7rem;
  line-height: 2.5rem;
  text-align: center;
}

.info-noti-modal .ant-btn-primary {
  border-color: #008ccf !important;
  background: #008ccf !important;
  height: 3.4rem;
  line-height: 3rem;
}

.info-noti-modal .anticon.anticon-close {
  color: #fff !important;
}
