/* MEDIA QUERY MANAGER */
/*
        0-600       Phone
        600-900     Tablet portrait 
        900-1200    Tablet landscape
        (1200-1800  Normal styles apply)
        1800 +      Big Dektop 
         
        Breakpoint argument choices:
        - phone 
        - tab-port
        - tab-land
        - big-desktop
        */

/* Desktop > 1440px(original design) */

@media only screen and (min-width: 90.0625em) {
  html {
    font-size: 62.5%; /* 1rem = 12px, 12/16 = 75% */
  }
}

/* 1200px */
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%; /* 1 rem = 9px, 9/16 = 56.25% */
  }
}

/* 900px */
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 56.25%; /* 1 rem = 8px, 8/16 = 50% */
  }
}

/* 320px */
@media only screen and (max-width: 20em) {
  html {
    font-size: 43.75%; /* 1 rem = 7px, 7/16 = 43.75% */
    overflow-x: hidden;
  }
}
